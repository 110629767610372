/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container-nomenclatures {
    top: 9px;
    float: right;
    position: absolute;
    display: block;
    right: 9px;
    left: 11%;
}

.sidebar {
    border-style: none;
    border-right-style: none;
    border-right: 1px solid #7c7c7c;
    width: 10%;
    height: 95%;
}

.container{
    max-width:100%;
    float:left;
}

.content-container {
    max-width: 100%;
    float: right;
    position: relative;
    display:block;
    top: 43px;
    left: 185px;
}

.vertical-center {
    vertical-align: middle !important;
    text-decoration: none !important;
}

.border-navbar {
    border-bottom: solid #596980 !important;
}

.container-master-detail {
    margin: 0.5em;
    height: 30vh;
}

.dx-link-icon{
    text-decoration: none !important;
}

.dark-theme-navmenu {
    /*background-color: #465672;*/
    overflow-x: hidden;
    overflow-y: hidden;
}

.dropdown-item:hover{
    background-color: #465672;
    color: white;
}

.dark-theme-dropdown-menu {
    margin-top: 1em;
    background-color: #465672;
}

.dark-theme-dropdown-toggler{
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.dark-theme-dropdown-toggler:hover{
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.dark-theme-dropdown-toggler:focus{
    background-color: rgba(0, 0, 0, 0);
    border: none;
}

.nav-menu-item-custom{
    font-size: 1rem;
    color: white !important;
}

.dx-command-expand {
    vertical-align: middle !important;
}

.risk-assessment-master-detail{
    margin: 0.5em;
    height: 100%;
}

.audit-overlay-wrapper {
    z-index: 1502;
    position: fixed;
    left: 0px;
    top: 0px;
    transform: translate(0px);
}

.audit-overlay-content {
    width: 70vw;
    height: 80vh;
    z-index: 1502;
    margin: 0px;
    left: 0px;
    top: 0px;
    transform: translate(288px, 59px);
    transition: none 0s ease 0s;
    opacity: 1;
}

.audit-popup-title {
    max-width: 48px;
}

.audit-toolbar-center {
    margin: 0px 38px 0px 62.6333px;
    float: none;
}

.popup-wrapper {
   margin-top: 1em;
   text-align: center;
   width: 100%;
}

.mergecells {
    display: table-cell;
    vertical-align: middle !important;
    border-color: rgba(61, 70, 109, 0.5) !important;
    border-style: solid !important;
    border-width: 0.2px 0.2px 0.2px 0.2px !important;
    background: white !important;
    color: black !important;
}

.mergecellselected {
    background: rgba(175, 21, 30, 0.8) !important;
    color: white !important;
}  
.audit-popup-content {
    height: 378.6px;
    max-height: none;
}

.vertical-element {
    margin-top: 33vh;
}

.listSubheaderNomenclatures{
    color:blue;
}