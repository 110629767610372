 .dx-field-all-statuses{
	
	display: inline-flex;
	padding-right: 5px;
	

}

.offer-checkBox{
	
	padding-left: 25px;

}
