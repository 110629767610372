.dx-accordion-item-title {
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    color: #333;
    padding: 9px 12px;
    font-size: 15px;
}

.dx-widget, .dx-widget *, .dx-widget ::after, .dx-widget ::before, .dx-widget::after, .dx-widget::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 15px;
}

.list {
    width: 230px;
    border-right: 1px solid #ddd;
}

.accordionDiv {
    margin-left: 10px;
    width: 100%;
    padding-right: 5px;
}

.accordionLink {
    text-decoration: none;
    color: black;
    margin-left: 10px;
    width: 100%;
    display: block;
    line-height: 24px;
    font-size: 14px;
    padding-right:5px;
}
.logo {
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    white-space: normal;
    text-align: center;
    color: white;
    padding-left: 5px;
}