.dx-field-label-task {
    float: left;
    padding-right: 5px;
    width: 15%;
    padding-top: 7px;
}

.dx-field-control-task {
    float: left;
    padding-right: 5px;
    width: 85%;
}

.dx-button-task {
    float: right;
    padding-right: 5px;
}

.dx-field-label-radio {
    float: left;
    padding-right: 5px;
    width: 30%;
    padding-top: 5px;
}

.dx-control-task {
    float: right;
    padding-right: 5px;
    padding-top: 3px;
    width: 70%;
}

.dx-radiogroup .dx-radiobutton, .dx-radiogroup .dx-radiobutton-icon {
    margin: 4px 0;
}

.dx-field-button {
    width: 100%;
}
